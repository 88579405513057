<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单ID"><a-input v-model="query.orderId" placeholder="请输入订单ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码"><a-input v-model="query.mobile" placeholder="请输入手机号码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="类型">
							<a-select style="width: 100%" v-model="query.type" placeholder="请选择类型">
								<a-select-option value="">全部</a-select-option>
								<a-select-option :value="1">代理申请</a-select-option>
								<a-select-option :value="2">补货</a-select-option>
								<a-select-option :value="3">零售</a-select-option>
								<a-select-option :value="4">代理申请(线下)</a-select-option>
								<a-select-option :value="5">补货(线下)</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="状态">
							<a-select style="width: 100%" v-model="query.status" placeholder="请选择状态">
								<a-select-option :value="3">全部</a-select-option>
								<a-select-option :value="0">待审核</a-select-option>
								<a-select-option :value="1">被驳回</a-select-option>
								<a-select-option :value="-10">待确认</a-select-option>
								<a-select-option :value="2">已通过</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="时间"><a-range-picker style="width: 100%" :value="selectedDateRange" @change="onChange" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="发货人"><a-input v-model="query.sender" placeholder="请输入手机号码/ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="产品名称">
							<a-select style="width: 100%" v-model="query.productId" placeholder="请选择产品" :allow-clear="true">
								<a-select-option v-for="item in goodsOptions" :key="item.id" :value="item.id">
									<a-space>
										<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
										{{ item.productName }}
									</a-space>
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="支付方式">
							<a-select style="width: 100%" v-model="query.payType" placeholder="请选择支付方式" :allow-clear="true">
								<a-select-option :value="1">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-wechat.png')" />
										<span>微信</span>
									</a-space>
								</a-select-option>
								<a-select-option :value="2">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-alipay.png')" />
										<span>支付宝</span>
									</a-space>
								</a-select-option>
								<a-select-option :value="3">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-balance.png')" />
										<span>余额</span>
									</a-space>
								</a-select-option>
								<a-select-option :value="5">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-offline.png')" />
										<span>线下</span>
									</a-space>
								</a-select-option>
								<a-select-option :value="6">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-alipay.png')" />
										<span>支付宝</span>
										<span>+</span>
										<a-avatar :size="24" :src="require('@/assets/icon-balance.png')" />
										<span>余额</span>
									</a-space>
								</a-select-option>
								<a-select-option :value="7">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-wechat.png')" />
										<span>微信</span>
										<span>+</span>
										<a-avatar :size="24" :src="require('@/assets/icon-balance.png')" />
										<span>余额</span>
									</a-space>
								</a-select-option>
								<a-select-option :value="8">
									<a-space>
										<a-avatar :size="24" :src="require('@/assets/icon-quick_payment.png')" />
										<span>快捷支付</span>
									</a-space>
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="支付通道">
							<a-select style="width: 100%" v-model="query.paySite" placeholder="请选择支付通道">
								<a-select-option value="">全部</a-select-option>
								<a-select-option v-for="option in paySiteOptions" :key="option.paySite" :value="option.paySite">
									{{option.payName}}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="代理商申请管理">
			<a-space slot="extra"><a-button type="primary" @click="onExport">导出</a-button></a-space>
			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.countUser }}</span>
				<span>购买数量：{{ lists.countNum }}</span>
				<span>订单金额：{{ lists.countMoney }}</span>
			</div>

			<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="id" title="ID" data-index="id" />
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.nickname }}
						<br />
						({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="nums" title="数量" data-index="nums">
					<template slot-scope="text, record">
						{{ record.nums }}
						<span v-if="parseFloat(record.giveNum) > 0"> + {{ record.giveNum }}</span>
					</template>
				</a-table-column>
				<!-- <a-table-column key="giveNum" title="赠送数量" data-index="giveNum" /> -->
				<a-table-column key="money" title="金额" data-index="money">
					<template slot-scope="text, record">
						{{ record.money }}
						<div v-if="parseFloat(record.bond) > 0">(保证金:{{ record.bond }})</div>
					</template>
				</a-table-column>
				<a-table-column key="payType" title="支付渠道" data-index="payType">
					<template slot-scope="payType, record">
						<a-space>
							<a-avatar :size="24" :src="require('@/assets/icon-wechat.png')" v-if="payType == 1" />
							<a-avatar :size="24" :src="require('@/assets/icon-alipay.png')" v-else-if="payType == 2" />
							<a-avatar :size="24" :src="require('@/assets/icon-balance.png')" v-else-if="payType == 3" />
							<a-avatar :size="24" :src="require('@/assets/icon-cart.png')" v-else-if="payType == 4" />
							<a-avatar :size="24" :src="require('@/assets/icon-offline.png')" v-else-if="payType == 5" />
							<a-avatar :size="24" :src="require('@/assets/icon-quick_payment.png')" v-else-if="payType == 8" />
							<a-tooltip placement="top" v-if="record.blendRemarks">
								<template slot="title">
									<span>{{ record.blendRemarks }}</span>
								</template>
								<a-icon type="question-circle" />
							</a-tooltip>
							<span>{{ record.payTypeStr }}</span>
						</a-space>
					</template>
				</a-table-column>
				<a-table-column key="siteStr" title="支付通道" data-index="siteStr">
					<template slot-scope="siteStr">
						<a-space>
							{{ siteStr }}
						</a-space>
					</template>
				</a-table-column>
				<a-table-column key="payUrls" title="产品类型" data-index="payUrls">
					<template slot-scope="urls, record">
						<span v-if="urls.length === 0">暂无</span>
						<a-space v-else>
							<!-- <vue-viewer :images="urls"></vue-viewer> -->
							<a-avatar shape="square" :size="64" :src="url" v-for="(url, idx) in urls" :key="idx" @click="onPreview(url)" />
						</a-space>
					</template>
				</a-table-column>
				<!-- <a-table-column key="typeStr" title="类型" data-index="typeStr" /> -->
				<a-table-column key="type" title="类型" data-index="type">
					<template slot-scope="status, record">
						<a-tag :color="['', '#2db7f5', '#87d068', '#e98f36', '#108ee9', '#a4579d'][record.type]">{{ record.typeStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="orange" v-if="status == 0 || status == 9">{{ record.statusStr }}</a-tag>
						<a-tag color="red" v-else-if="status == 1">{{ record.statusStr }}</a-tag>
						<a-tag color="green" v-else-if="status == 2">{{ record.statusStr }}</a-tag>
						<a-tag color="pink" v-else-if="status == 3">{{ record.statusStr }}</a-tag>
						<a-tag color="orange" v-else-if="status == -10">{{ record.statusStr }}</a-tag>
						<a-tag v-else>{{ record.statusStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="sendMobile" title="发货人" data-index="sendMobile">
					<template slot-scope="status, record">
						<span v-if="record.sendNickname && record.sendMobile">
							{{ record.sendNickname }}
							<a href="javascript:;" v-if="record.status == 0" @click="onUpdateSender(record)"><a-icon type="form" /></a>
							<br />
							({{ record.sendMobile }})
						</span>
						<span v-else></span>
					</template>
				</a-table-column>
				<a-table-column key="ctime" title="创建时间" data-index="ctime" />
				<a-table-column key="action" title="操作" fixed="right" :width="140">
					<template slot-scope="text, record">
						<a-space>
							<span class="xs-hidden" v-if="record.status == 0">
								<a-popconfirm title="确认要发送提醒短信吗？" @confirm="onSendSMS(record)">
									<a-button size="small">发短信</a-button>
								</a-popconfirm>
							</span>
							<a-button size="small" type="danger" v-if="record.status == 9" @click="onReject(record)">驳回</a-button>
							<template v-if="record.status == 0 || record.status == 9">
								<a-popconfirm title="确认要通过吗？" @confirm="onAudit(record.status, record.id, 2)">
									<a-button size="small" type="primary">通过</a-button>
								</a-popconfirm>
							</template>
							<a-button size="small" type="primary" @click="onCommission(record)" v-if="record.commissionDetail == 1">分佣详情</a-button>
							<a-button size="small" type="danger" @click="onCancel(record)" v-if="[1, 2].includes(record.reverse)">取消</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal v-model="modal.visible" title="驳回理由" @ok="onAudit(modal.data.status, modal.data.id, 1, modal.text)">
			<a-textarea placeholder="请输入驳回理由" :rows="4" v-model="modal.text" />
		</a-modal>

		<a-modal v-model="modalCommission.visible" title="分佣详情" :width="800" :footer="null">
			<a-table rowKey="key" :data-source="modalCommission.data" :pagination="false" :scroll="{ x: 'max-content' }">
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.nickname }}
						<br />
						({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="money" title="金额" data-index="money" />
				<a-table-column key="serviceCharge" title="手续费" data-index="serviceCharge" />
				<a-table-column key="incomeTypeStr" title="分佣类型" data-index="incomeTypeStr" />
				<a-table-column key="commissionTypeStr" title="分佣途径" data-index="commissionTypeStr" />
				<a-table-column key="statusStr" title="状态" data-index="statusStr" />
				<a-table-column key="settleTime" title="结算时间" data-index="settleTime" />
			</a-table>
		</a-modal>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import VueViewer from 'vue-viewerjs';
	const formatter = 'YYYY-MM-DD';
	const DEFAULT_QUERY = {
		page: 1,
		size: 10,
		orderId: undefined,
		status: undefined,
		type: undefined,
		mobile: undefined,
		sender: undefined,
		beginTime: undefined,
		endTime: undefined,
		productId: undefined,
		payType: undefined,
		paySite: undefined
	};

	export default {
		components: { VueViewer },
		data() {
			return {
				goodsOptions: [],
				paySiteOptions: [],
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				},
				modal: {
					visible: false,
					text: '',
					data: {}
				},
				modalCommission: {
					visible: false,
					data: []
				}
			};
		},
		computed: {
			selectedDateRange() {
				const { beginTime, endTime } = this.query;
				if (beginTime && endTime) {
					return [moment(beginTime, formatter), moment(endTime, formatter)];
				} else {
					return null;
				}
			}
		},
		async mounted() {
			this.getAgents();
			const response = await this.$api.get('/user_query_product/2');
			this.goodsOptions = (response && response.data) || [];

			const res = await this.$api.get('/get_pay_channel')
			this.paySiteOptions = (res && res.data) || [];
		},
		methods: {
			async getAgents() {
				const response = await this.$api.get('/agent_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					response.data.data = response.data.data.map(v => {
						v.payUrls = String(v.payUrl)
							.split(',')
							.filter(v => !!v);
						return v;
					});
					this.lists = Object.assign(this.lists, response.data);
				}
			},
			async onPageChange(pagination) {
				this.query.page = pagination.current;
				this.query.size = pagination.pageSize;
				this.getAgents();
			},
			async onSearch() {
				this.query.page = 1;
				this.getAgents();
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY);
				this.getAgents();
			},
			async onChange(o, dateStr) {
				if (dateStr.length === 2) {
					this.query.beginTime = dateStr[0];
					this.query.endTime = dateStr[1];
				} else {
					this.query.beginTime = undefined;
					this.query.endTime = undefined;
				}
			},
			async onReject(record) {
				this.modal.data = record;
				this.modal.text = '';
				this.modal.visible = true;
			},
			async onAudit(type, id, status, remark = '') {
				// type 对应 record 里面的 status
				var response = await this.$api.post(type == 9 ? '/instrument_examine_order' : '/agent_new_examine', {
					id,
					status,
					remark
				});
				// console.info('response', response);
				if (response && response.code == 200) {
					this.getAgents();
					this.modal.visible = false;
					this.$message.success('操作成功!');
				}
			},
			async onSendSMS(record) {
				var response = await this.$api.get('/send_sms', {
					params: {
						id: record.id,
						sendMobile: record.sendMobile
					}
				});
				console.info('response', response);
				if (response && response.code == 200) {
					this.getAgents();
					// this.modal.visible = false
					this.$message.success('操作成功!');
				}
			},
			async onUpdateSender(record) {
				var value = record.sendMobile;
				this.$confirm({
					title: '修改发货人',
					content: h => {
						return h('a-input', {
							props: {
								defaultValue: value,
								placeholder: '请输入收货人手机号码',
								maxLength: 11
							},
							on: {
								change: e => {
									console.info('value', e.target.value);
									value = e.target.value;
								}
							}
						});
					},
					onOk: async () => {
						console.info('value', value);
						if (value == record.sendMobile) {
							this.$message.success('操作成功!');
							return true;
						}
						if (/^1[2-9]\d{9}$/.test(value)) {
							var response = await this.$api.post('/update_send_order', {
								id: record.id,
								mobile: value
							});
							console.info('response', response);
							if (response && response.code == 200) {
								this.getAgents();
								this.$message.success('操作成功!');
							} else {
								this.$message.error(response.msg);
							}
							return true;
						} else {
							this.$message.error('请输入正确的手机号码');
						}
						return Promise.reject();
					}
				});
			},
			async onExport() {
				this.$store.dispatch('setLoading', true);
				const response = await this.$api.get('/agent_export', {
					params: this.query,
					responseType: 'arraybuffer'
				});
			},
			async onCancel(record) {
				this.$confirm({
					title: '提示',
					content: '确定要取消订单?',
					onOk: async () => {
						var response = null;
						if (record.reverse == 2) {
							response = await this.$api.post(`/agent_refund_offline/${record.id}`);
						} else {
							response = await this.$api.post(`/agent_refund/${record.id}`);
						}
						console.info('response', response);
						if (response && response.code == 200) {
							this.getAgents();
							this.$message.success('操作成功!');
						} else {
							this.$message.error(response.msg);
						}
					}
				});
			},
			async onCommission(record) {
				const response = await this.$api.get(`/commission_detail/${record.id}`);
				console.info('response', response);
				if (response && response.code == 200) {
					this.modalCommission.data = response.data.map(item => {
						item.key = Math.random()
							.toString(16)
							.substr(2);
						return item;
					});
					this.modalCommission.visible = true;
				} else {
					this.$message.error(response.msg);
				}
			}
		}
	};
</script>

<style lang="less">
	@media (max-width: 500px) {
		.xs-hidden {
			display: none;
		}
	}
</style>